
import ApiSalesWorkOrder, { SalesWorkOrder, SalesWorkOrderStatus } from "@/api/ApiSalesWorkOrder"
import { Rules } from "@/typings/Common"
import dd from "dingtalk-jsapi"
import { prop, setup, Vue } from "vue-class-component"
import { Form, message } from "ant-design-vue"

class Props {
  defaultSource = prop<SalesWorkOrder | null>({ default: null })
}

export default class SalesWorkOrderForm extends Vue.with(Props) {
  isLoading = false
  formBody = ApiSalesWorkOrder.createForm()

  rules = setup(() => ({
    customer_name: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
    customer_address: [{ required: true, message: "请输入客户地址", trigger: "blur" }],
    sign_man: [{ required: true, message: "请输入签约人", trigger: "blur" }],
    sign_phone: [{ required: true, message: "请输入签约电话", trigger: "blur" }]
  }) as Rules<keyof SalesWorkOrder>)

  declare $refs: {
    form: typeof Form
  }

  get disabled() {
    return this.defaultSource && this.defaultSource.status !== SalesWorkOrderStatus.CREATED
  }

  mounted() {
    if (this.defaultSource) {
      this.formBody = { ...this.defaultSource }
    }
  }

  async submit() {
    try {
      this.$refs.form.validate()

      this.isLoading = true
      try {
        if (this.defaultSource) {
          await ApiSalesWorkOrder.edit(this.defaultSource.id, this.formBody)
        } else {
          const body = { ...this.formBody, id: undefined }
          await ApiSalesWorkOrder.create(body)
        }

        message.success('提交成功')
        history.back()
      } catch (e) {
      } finally {
        this.isLoading = false
      }

    } catch (e) {
      message.error('有必填信息未填写')
    }
  }

  reset() {
    dd.device.notification.confirm({
      title: '警告',
      message: '确定要重置表单吗？',
      buttonLabels: ['取消', '确定'],
    }).then(res => {
      if (res.buttonIndex === 1) {
        if (this.defaultSource) {
          this.formBody = { ...this.defaultSource }
        } else {
          this.formBody = ApiSalesWorkOrder.createForm()
        }
      }
    })
  }
}
